<template>
    <div>
      <b-container class="mt-5 mb-5">

        <Stepper :step="step" :steps="steps" />

        <template v-if="step == 1">
          <h1 class="fs-4 font-weight-bold mb-4">Bedrijfsgegevens</h1>
          <p class="text-muted">Om te kunnen starten met de verkoop hebben we nog een aantal gegevens van je bedrijf nodig. Nadat je deze hebt aangeleverd kun je direct starten met de verkoop.</p>

          <b-card class="mb-4">
            <div class="mb-3">
              <label class="required">Rechtsvorm</label>
              <b-form-select v-model="legalForm" :options="legalFormOptions" />
            </div>
            <div class="mb-3">
              <label class="required">E-mailadres</label>
              <b-form-input/>
            </div>
            <div class="mb-3">
              <label class="required">Telefoonnummer</label>
              <b-form-input/>
            </div>
          </b-card>
            
          <div class="clearfix">
            <b-button @click="saveCompanyDetails" variant="primary" class="float-right">
              Volgende
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </b-button>
          </div>
        </template>

        <template v-if="step == 2">
          <h3 class="fs-4 font-weight-bold mb-4">Bestuurders</h3>
          <p class="text-muted">Vul de gegevens in van alle bestuurders van dit bedrijf.</p>
          <b-card class="mb-4">
            <b-row>
              <b-col md="4">
                <div class="mb-3">
                  <label class="required">Aanhef</label>
                  <b-form-select v-model="gender" :options="genderOptions" />
                </div>
              </b-col>
              <b-col md="4">
                <div class="mb-3">
                  <label class="required">Voornaam</label>
                  <b-form-input/>
                </div>
              </b-col>
              <b-col md="4">
                <div class="mb-3">
                  <label class="required">Achternaam</label>
                  <b-form-input/>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <div class="mb-3">
                  <label class="required">E-mailadres</label>
                  <b-form-input/>
                </div>
              </b-col>
              <b-col md="4">
                <div class="mb-3">
                  <label class="required">Geboortedatum</label>
                  <b-form-input type="date" max="9999-12-31" />
                </div>
              </b-col>
              <b-col md="4">
                <div class="mb-3">
                  <label class="required">Geboorteplaats</label>
                  <b-form-input/>
                </div>
              </b-col>
            </b-row>
          </b-card>

          <div class="clearfix">
            <b-button @click="step = 1" variant="outline-secondary" class="float-left">
              <font-awesome-icon icon="fa-solid fa-chevron-left" />
              Terug
            </b-button>
            <b-button @click="saveOwners" variant="primary" class="float-right">
              Opslaan
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </b-button>
          </div>
        </template>
        
      </b-container>
    </div>
  </template>
  
  <script>
  import Stepper from '@/components/Stepper'

  export default {
      metaInfo: {
          title: 'Organisatie',
      },
      components: {
        Stepper
      },
      data() {
          return {
            step: 1,
            steps: [
              { icon: 'fa-user', title: 'Bedrijfsgegevens' },
              { icon: 'fa-user', title: 'Bestuurders' },
            ],
            legalFormOptions: [
              { text: 'Selecteer een optie', value: null },
              { text: 'Eenmanszaak', value: 'eenmanszaak' },
              { text: 'Maatschap', value: 'maatschap' },
              { text: 'Vennootschap onder Firma (VOF)', value: 'vennootschap-onder-firma' },
              { text: 'Commanditaire Vennootschap (CV)', value: 'commanditaire-vennootschap' },
              { text: 'Besloten Vennootschap (BV)', value: 'besloten-vennootschap' },
              { text: 'Stichting of Vereniging', value: 'stichting-vereniging' },
            ],
            legalForm: null,
            genderOptions: [
              { text: 'Selecteer een optie', value: null },
              { text: 'Dhr.', value: 'male' },
              { text: 'Mevr.', value: 'female' },
            ],
            gender: null,
          }
      },
      computed: {},
      methods: {
        saveCompanyDetails: function() {
          this.step = 2;
        },
        saveOwners: function() {
          alert('test');
        },
      },
      created() {}
  }
  </script>